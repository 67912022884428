<template>
  <b-overlay :show="loading">
    <b-tabs no-fade>
      <b-tab active>
        <template #title>
          <feather-icon class="mb-lg-none" icon="ClockIcon" />
          <span class="d-none d-lg-block">A cobrar</span>
        </template>
        <security-deposits-list
          title="Fiances pendents de cobrar"
          :items="parsedChargableSecurityDeposits"
          sort-field="checkinOrder"
          sort-order="asc"
          list-type="PENDING"
          @updated="onItemsUpdated"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon class="mb-lg-none" icon="RefreshCwIcon" />
          <span class="d-none d-lg-block">A retornar</span>
        </template>
        <security-deposits-list
          title="Fiances pendents de retornar"
          :items="parsedRefundableSecurityDeposits"
          sort-field="checkoutOrder"
          sort-order="asc"
          list-type="REFUNDABLE"
          @updated="onItemsUpdated"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="ThumbsUpIcon" />
          <span class="d-none d-lg-block">Retornades</span>
        </template>
        <security-deposits-list
          title="Fiances retornades"
          :items="parsedRefundedSecurityDeposits"
          sort-field="checkoutOrder"
          sort-order="desc"
          list-type="REFUNDED"
        />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import { BOverlay, BTabs, BTab } from "bootstrap-vue";
import SecurityDepositsList from "@/views/bookings/security-deposits/components/SecurityDepositsList.vue";
import { notifyError, removeHtmlTags } from "@/utils/methods";
import {
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";

export default {
  components: {
    BOverlay,
    BTabs,
    BTab,
    SecurityDepositsList,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    chargableSecurityDeposits() {
      return this.$store.getters["bookings/chargableSecurityDeposits"];
    },
    parsedChargableSecurityDeposits() {
      if (!this.chargableSecurityDeposits.length) {
        return [];
      }

      return this.chargableSecurityDeposits.map((booking) => {
        return {
          bookingUuid: booking.uuid || this.$t("No definit"),
          bookingUri: booking["@id"] || null,
          localizator: booking.localizator || this.$t("No definit"),
          source: booking.source || null,
          highlighted: booking.highlighted || false,
          client: booking.client?.fullName || this.$t("No definit"),
          clientUuid: booking.client?.uuid || null,
          accommodation: booking.accommodation?.name || this.$t("No definit"),
          accommodationUuid: booking.accommodation?.uuid || null,
          checkinOrder:
            formatDateStringToDatabaseDate(booking.checkin) ||
            this.$t("No definit"),
          checkin:
            formatDateStringToDate(booking.checkin) || this.$t("No definit"),
          checkoutOrder:
            formatDateStringToDatabaseDate(booking.checkout) ||
            this.$t("No definit"),
          checkout:
            formatDateStringToDate(booking.checkout) || this.$t("No definit"),
          amount: this.getSecurityDepositAmount(booking) || null,
          requested:
            !!booking.logs?.find(
              (log) =>
                log.type === "CLIENT_SECURITY_DEPOSIT_PAYMENT_REQUEST_SENT"
            ) || false,
          commentsAlert: this.securityDepositHasComments(booking) || false,
          commentsText: this.getSecurityDepositComments(booking) || null,
        };
      });
    },
    refundableSecurityDeposits() {
      return this.$store.getters["bookings/refundableSecurityDeposits"];
    },
    parsedRefundableSecurityDeposits() {
      if (!this.refundableSecurityDeposits.length) {
        return [];
      }

      return this.refundableSecurityDeposits.map((booking) => {
        return {
          bookingUuid: booking.uuid || this.$t("No definit"),
          bookingUri: booking["@id"] || null,
          localizator: booking.localizator || this.$t("No definit"),
          source: booking.source || null,
          highlighted: booking.highlighted || false,
          client: booking.client?.fullName || this.$t("No definit"),
          clientUuid: booking.client?.uuid || null,
          accommodation: booking.accommodation?.name || this.$t("No definit"),
          accommodationUuid: booking.accommodation?.uuid || null,
          checkinOrder:
            formatDateStringToDatabaseDate(booking.checkin) ||
            this.$t("No definit"),
          checkin:
            formatDateStringToDate(booking.checkin) || this.$t("No definit"),
          checkoutOrder:
            formatDateStringToDatabaseDate(booking.checkout) ||
            this.$t("No definit"),
          checkout:
            formatDateStringToDate(booking.checkout) || this.$t("No definit"),
          paidAmount: this.getSecurityDepositPaidAmount(booking) || null,
          requested:
            !!booking.logs?.find(
              (log) => log.type === "OWNER_SECURITY_DEPOSIT_REFUND_REQUEST_SENT"
            ) || false,
          commentsAlert: this.securityDepositHasComments(booking) || false,
          commentsText: this.getSecurityDepositComments(booking) || null,
        };
      });
    },
    refundedSecurityDeposits() {
      return this.$store.getters["bookings/refundedSecurityDeposits"];
    },
    parsedRefundedSecurityDeposits() {
      if (!this.refundedSecurityDeposits.length) {
        return [];
      }

      return this.refundedSecurityDeposits.map((booking) => {
        return {
          bookingUuid: booking.uuid || this.$t("No definit"),
          bookingUri: booking["@id"] || null,
          localizator: booking.localizator || this.$t("No definit"),
          source: booking.source || null,
          highlighted: booking.highlighted || false,
          client: booking.client?.fullName || this.$t("No definit"),
          clientUuid: booking.client?.uuid || null,
          accommodation: booking.accommodation?.name || this.$t("No definit"),
          accommodationUuid: booking.accommodation?.uuid || null,
          checkinOrder:
            formatDateStringToDatabaseDate(booking.checkin) ||
            this.$t("No definit"),
          checkin:
            formatDateStringToDate(booking.checkin) || this.$t("No definit"),
          checkoutOrder:
            formatDateStringToDatabaseDate(booking.checkout) ||
            this.$t("No definit"),
          checkout:
            formatDateStringToDate(booking.checkout) || this.$t("No definit"),
          paidAmount: this.getSecurityDepositPaidAmount(booking),
          refundedAmount: this.getSecurityDepositRefundedAmount(booking),
          refundedAmountAlert:
            this.getSecurityDepositRefundedAmountAlert(booking) || false,
          commentsAlert: this.securityDepositHasComments(booking) || false,
          commentsText: this.getSecurityDepositComments(booking) || null,
        };
      });
    },
  },
  created() {
    this.fetchSecurityDeposits();
    this.fetchRefundedSecurityDeposits();
  },
  methods: {
    fetchSecurityDeposits() {
      this.loading = true;
      const promises = [
        this.$store.dispatch(
          "bookings/fetchBookingsWithChargableSecurityDeposit"
        ),
        this.$store.dispatch(
          "bookings/fetchBookingsWithRefundableSecurityDeposits"
        ),
      ];
      Promise.all(promises)
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.description")
          )
        )
        .finally(() => {
          this.loading = false;
        });
    },
    fetchRefundedSecurityDeposits() {
      this.$store
        .dispatch("bookings/fetchBookingsWithRefundedSecurityDeposits")
        .catch(() => {
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.description")
          );
        });
    },
    getSecurityDepositAmount(booking) {
      if (!booking) return null;
      const bookingServices = booking.services || [];
      const securityDepositService = bookingServices.find(
        (bookingService) => bookingService.service?.code === "SECURITY_DEPOSIT"
      );
      return securityDepositService?.pvpPrice || null;
    },
    getSecurityDepositPaidAmount(booking) {
      if (!booking) return null;
      const clientPayments = booking.clientPayments || [];
      const amount = clientPayments.reduce((total, clientPayment) => {
        if (
          clientPayment.scope !== "SECURITY_DEPOSIT" ||
          clientPayment.pvpAmount <= 0
        )
          return total;
        return total + clientPayment.pvpAmount;
      }, 0);
      return amount;
    },
    getSecurityDepositRefundedAmount(booking) {
      if (!booking) return null;
      const clientPayments = booking.clientPayments || [];
      const amount = clientPayments.reduce((total, clientPayment) => {
        if (
          clientPayment.scope !== "SECURITY_DEPOSIT" ||
          clientPayment.pvpAmount > 0
        )
          return total;
        return total + clientPayment.pvpAmount;
      }, 0);
      return amount !== 0 ? -amount : amount;
    },
    getSecurityDepositRefundedAmountAlert(booking) {
      if (!booking) return null;
      const clientPayments = booking.clientPayments || [];
      const paidAmount = clientPayments.reduce((total, clientPayment) => {
        if (
          clientPayment.scope !== "SECURITY_DEPOSIT" ||
          clientPayment.pvpAmount <= 0
        )
          return total;
        return total + clientPayment.pvpAmount;
      }, 0);
      const refundedAmount = clientPayments.reduce((total, clientPayment) => {
        if (
          clientPayment.scope !== "SECURITY_DEPOSIT" ||
          clientPayment.pvpAmount >= 0
        )
          return total;
        return total + clientPayment.pvpAmount;
      }, 0);
      return refundedAmount * -1 < paidAmount;
    },
    securityDepositHasComments(booking) {
      return booking.comments?.some(
        (comment) => comment.scope === "SECURITY_DEPOSIT"
      );
    },
    getSecurityDepositComments(booking) {
      return booking.comments
        ?.filter((comment) => comment.scope === "SECURITY_DEPOSIT")
        .reduce((text, comment) => {
          if (text === "") {
            return removeHtmlTags(comment.content);
          }

          if (!comment.content?.length) {
            return text;
          }

          return `${text} | ${removeHtmlTags(comment.content)}`;
        }, "");
    },
    onItemsUpdated(listType) {
      switch (listType) {
        case "PENDING":
          this.$store
            .dispatch("bookings/fetchBookingsWithChargableSecurityDeposit")
            .catch(() => {
              notifyError(
                this.$t("errors.fetchBookings.title"),
                this.$t("errors.fetchBookings.description")
              );
            });
          break;
        case "REFUNDABLE":
          this.$store
            .dispatch("bookings/fetchBookingsWithRefundableSecurityDeposits")
            .catch(() => {
              notifyError(
                this.$t("errors.fetchBookings.title"),
                this.$t("errors.fetchBookings.description")
              );
            });
          break;
        default:
          break;
      }
    },
  },
};
</script>
