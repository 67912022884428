<template>
  <div class="security-deposits-table">
    <!-- FILTER -->
    <b-row v-if="showSearch" class="mb-2 d-flex flex-wrap justify-content-end">
      <b-col cols="12" md="auto" class="mb-1 mb-md-0">
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="loading"
        />
      </b-col>
    </b-row>

    <!-- TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          responsive
          class="position-relative"
          :busy="loading"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="items"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: localizator -->
          <template #cell(localizator)="data">
            <b-booking-localizator
              :localizator="data.value"
              :source="data.item.source"
              :highlighted="data.item.highlighted"
              :fictitious="data.item.fictitious"
            />
          </template>

          <!-- Column: accommodation -->
          <template #cell(accommodation)="data">
            <b-accommodation
              :uuid="data.item.accommodationUuid"
              :name="data.value"
            />
          </template>

          <!-- Column: client -->
          <template #cell(client)="data">
            <b-client :uuid="data.item.clientUuid" :name="data.value" />
          </template>

          <!-- Column: checkinOrder -->
          <template #cell(checkinOrder)="data">
            {{ data.item.checkin }}
          </template>

          <!-- Column: checkoutOrder -->
          <template #cell(checkoutOrder)="data">
            {{ data.item.checkout }}
          </template>

          <!-- Column: amount -->
          <template #cell(amount)="data">
            {{ data.value ? formatCurrency(data.value) : $t("No definit") }}
          </template>

          <!-- Column: paidAmount -->
          <template #cell(paidAmount)="data">
            {{
              data.value || data.value === 0
                ? formatCurrency(data.value)
                : $t("No definit")
            }}
          </template>

          <!-- Column: refundedAmount -->
          <template #cell(refundedAmount)="data">
            <div class="d-flex align-items-center">
              {{
                data.value || data.value === 0
                  ? formatCurrency(data.value)
                  : $t("No definit")
              }}
              <feather-icon
                v-if="data.item.refundedAmountAlert"
                icon="AlertTriangleIcon"
                class="text-danger ml-50"
                size="20"
              />
            </div>
          </template>

          <!-- Column: actions -->
          <template #cell(actions)="data">
            <feather-icon
              v-if="data.item.requested"
              icon="CheckIcon"
              class="text-success"
              size="20"
            />
            <feather-icon
              v-else
              icon="SlashIcon"
              class="text-danger cursor-pointer"
              @click="addLog(data.item)"
              size="20"
            />
            <feather-icon
              v-if="data.item.commentsAlert"
              icon="MessageSquareIcon"
              class="text-danger ml-50"
              size="20"
              :id="`comments-alert-${data.item.bookingUuid}`"
            />
            <b-tooltip
              v-if="data.item.commentsAlert && data.item.commentsText"
              :target="`comments-alert-${data.item.bookingUuid}`"
              triggers="hover"
              no-fade
              placement="left"
              :delay="{ show: 500, hide: 0 }"
            >
              {{ data.item.commentsText }}
            </b-tooltip>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!loading"
      v-if="items.length > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BSpinner,
  BTooltip,
} from "bootstrap-vue";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import BClient from "@foravila-core/components/b-client/BClient.vue";
import { formatCurrency } from "@/utils/formatters";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BSpinner,
    BBookingLocalizator,
    BAccommodation,
    BClient,
    BTooltip,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
    sortField: {
      type: String,
      default: () => "checkinOrder",
    },
    sortOrder: {
      type: String,
      default: () => "asc",
    },
    listType: {
      type: String,
      required: true,
      validator(value) {
        return ["PENDING", "REFUNDABLE", "REFUNDED"].includes(value);
      },
    },
  },
  data() {
    return {
      isBusy: false,
      sortBy: "checkinOrder",
      sortDesc: false,
      perPage: 10,
      pageOptions: [5, 10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      fields: [
        { key: "localizator", label: this.$t("Nº reserva") },
        { key: "accommodation", label: this.$t("Allotjament") },
        { key: "client", label: this.$t("Client") },
        { key: "checkinOrder", label: this.$t("Entrada"), sortable: true },
        { key: "checkoutOrder", label: this.$t("Sortida"), sortable: true },
      ],
      selectedItem: null,
      formatCurrency,
    };
  },
  watch: {
    items(items) {
      this.totalRows = items.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.sortBy = this.sortField;
    this.sortDesc = this.sortOrder === "desc";
    this.initTableColumns();
  },
  methods: {
    initTableColumns() {
      if (["PENDING"].includes(this.listType))
        this.fields.push({
          key: "amount",
          label: this.$t("Import"),
          sortable: true,
        });
      if (["REFUNDABLE", "REFUNDED"].includes(this.listType))
        this.fields.push({
          key: "paidAmount",
          label: this.$t("Import pagat"),
          sortable: true,
        });
      if (["REFUNDED"].includes(this.listType))
        this.fields.push({
          key: "refundedAmount",
          label: this.$t("Import retornat"),
        });
      if (["PENDING", "REFUNDABLE"].includes(this.listType))
        this.fields.push({ key: "actions", label: "Recordat?" });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addLog(booking) {
      this.$store.dispatch("app/setLoading", true);

      let logType = null;

      switch (this.listType) {
        case "PENDING":
          logType = "CLIENT_SECURITY_DEPOSIT_PAYMENT_REQUEST_SENT";
          break;
        case "REFUNDABLE":
          logType = "OWNER_SECURITY_DEPOSIT_REFUND_REQUEST_SENT";
          break;
        default:
          break;
      }

      // Log the booking update
      this.$store
        .dispatch("bookings/addLog", {
          bookingUuid: booking.bookingUuid,
          log: {
            booking: booking.bookingUri,
            type: logType,
          },
        })
        .finally(() => {
          this.$emit("updated", this.listType);
          this.$store.dispatch("app/setLoading", false);
        });
    },
  },
};
</script>

<style lang="scss">
.security-deposits-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
