<template>
  <b-card :title="title">
    <security-deposits-table
      :items="items"
      :loading="loading"
      :sort-field="sortField"
      :sort-order="sortOrder"
      :list-type="listType"
      @updated="onItemsUpdated"
    />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import SecurityDepositsTable from "@/views/bookings/security-deposits/components/SecurityDepositsTable.vue";

export default {
  components: {
    BCard,
    SecurityDepositsTable,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    sortField: {
      type: String,
      default: () => "checkinOrder",
    },
    sortOrder: {
      type: String,
      default: () => "asc",
    },
    listType: {
      type: String,
      required: true,
      validator(value) {
        return ["PENDING", "REFUNDABLE", "REFUNDED"].includes(value);
      },
    },
  },
  computed: {
    loading() {
      return this.$store.getters["bookings/loading"];
    },
  },
  methods: {
    onItemsUpdated(listType) {
      this.$emit("updated", listType);
    },
  },
};
</script>
